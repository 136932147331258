import React, { useEffect, useRef } from "react";

function nanoid() {
  return new Date().getTime().toString();
}
let bot: any;
//import { SSE } from "sse.js";
let baseUrl = "/api";
const App = () => {
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bot = new window.ChatSDK({
      root: wrapper.current,

      config: {
        navbar: {
          title: "工匠智能助理",
        },
        robot: {
          avatar: "/avatar.jpg",
        },
        // 首屏消息
        messages: [
          {
            type: "system",
            content: {
              text: "工匠智能助理进入对话，为您服务",
            },
          },
          {
            type: "text",
            content: {
              text: "工匠智能助理为您服务，请问有什么可以帮您？",
            },
          },
          // {
          //   type: "card",
          //   content: {
          //     code: "switch-location",
          //   },
          // },
        ],
        // 快捷短语
        quickReplies: [{ name: "什么是工匠" }, { name: "工匠证书的有效期" }],
        // 输入框占位符
        placeholder: "请输入",
        // 侧边栏
        sidebar: [
          {
            code: "sidebar-suggestion",
            data: [
              {
                label: "疫情问题",
                list: [
                  "身边有刚从湖北来的人，如何报备",
                  "接触过武汉人，如何处理？",
                  "发烧或咳嗽怎么办？",
                  "去医院就医时注意事项",
                  "个人防护",
                  "传播途径",
                  "在家消毒",
                ],
              },
              {
                label: "法人问题",
                list: [
                  "企业设立",
                  "企业运行",
                  "企业变更",
                  "企业服务",
                  "企业注销",
                  "社会团体",
                  "民办非企业",
                ],
              },
            ],
          },
          {
            code: "sidebar-tool",
            title: "常用工具",
            data: [
              {
                name: "咨询表单",
                icon: "clipboard-list",
                url: "http://www.zjzxts.gov.cn/wsdt.do?method=suggest&xjlb=0&ymfl=1&uflag=1",
              },
              {
                name: "投诉举报",
                icon: "exclamation-shield",
                url: "http://www.zjzxts.gov.cn/wsdt.do?method=suggest&xjlb=1",
              },
              {
                name: "办事进度",
                icon: "history",
                url: "http://www.zjzwfw.gov.cn/zjzw/search/progress/query.do?webId=1",
              },
            ],
          },
          {
            code: "sidebar-phone",
            title: "全省统一政务服务热线",
            data: ["12345"],
          },
        ],
      },
      requests: {
        baseUrl,
        send(msg: any) {
          let isInit = false;

          const _id = nanoid();
          return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();
            let url = baseUrl + `/chat/q`;
            xhr.open("POST", url);
            const messages = bot.getApp().getMessageList();
            const history = messages
              .filter((item: any) => !item._id.startsWith("_FIRST_MSG_"))
              .map((item: any) => ({
                role: item.position === "left" ? "assistant" : "user",
                content: item.content.text,
              }));

            const sendData = {
              query: msg.content.text,
              history,
            };

            xhr.setRequestHeader("Content-Type", "application/json");
            const inputDom: any = document.querySelector(".Composer-input");
            inputDom.disabled = true;
            inputDom.placeholder = "回答中...";
            // 监听 readyStateChange 事件
            xhr.onreadystatechange = () => {
              // 如果 readyState 是 3，表示正在接收数据
              if (xhr.readyState === 3) {
                //console.log(xhr.responseText);
                if (!isInit) {
                  resolve({
                    _id,
                    type: "text",
                    content: { text: "内容输出中..." },
                  });
                  isInit = true;
                } else {
                  bot.getCtx().updateMessage(_id, {
                    _id,
                    type: "text",
                    content: { text: xhr.responseText },
                  });
                }
              }
              // 如果 readyState 是 4，表示完成请求
              if (xhr.readyState === 4) {
                inputDom.disabled = false;
                inputDom.placeholder = "请输入";
                if (xhr.status === 200) {
                  bot.getCtx().updateMessage(_id, {
                    _id,
                    type: "text",
                    content: { text: xhr.responseText },
                  });
                } else {
                  //this.chatList[currentResLocation].msg = "网络故障，回答失败，请稍后重试。";
                }
              }
            };
            // 发送请求
            xhr.send(JSON.stringify(sendData));
          });
        },
        autoComplete(data: any) {
          return {
            url: "/chat/associate",
            data: {
              k: data.text,
            },
          };
        },
      },
      handlers: {
        /* ... */
      },
    });

    bot.run();
  }, []);

  // 注意 wrapper 的高度
  return <div style={{ height: "100%" }} ref={wrapper} />;
};

export default App;
